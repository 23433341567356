import React, { FC, useCallback, useState, memo } from 'react'

import Button from '@ui/button'
import Typography from '@ui/typography'

import { IPromotion } from 'types/catalog'

import * as styles from './Card.module.scss'

interface IProps {
  promotion: IPromotion
  onFirstBtnClick?(): void
  onSecondBtnClick?(id: string): void
}

const Card: FC<IProps> = ({
  onSecondBtnClick,
  onFirstBtnClick,
  promotion: { title, description, '@id': id, picture, phone },
}) => {
  const [showPhone, setShowPhone] = useState<boolean>(false)

  const firstBtnClickHandler = useCallback(() => {
    setShowPhone(true)
    onFirstBtnClick?.()
  }, [setShowPhone, onFirstBtnClick])

  return (
    <div className={styles.card}>
      {picture?.path && <img src={picture?.path} alt="image" className={styles.img} />}

      <div className={styles.bottom}>
        <div className={styles.info}>
          <Typography type="h3_bold" component="div" className={styles.title}>
            {title}
          </Typography>
          <Typography type="body_2" component="div" className={styles.text} html={description} />
        </div>
        <div className={styles.buttons}>
          <Button
            className={styles.phone}
            onClick={firstBtnClickHandler}
            btnStyle="green"
            disabled={showPhone}
          >
            <Typography type="h6_bold">{showPhone ? phone : 'Показать номер'}</Typography>
          </Button>
          <Button onClick={() => onSecondBtnClick?.(id)} className={styles.call} btnStyle="white">
            <Typography type="h6_bold">Заказать звонок</Typography>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default memo(Card)
