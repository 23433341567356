import React, { FC, useMemo, useState, useCallback, memo, useEffect } from 'react'
import { TailSpin } from 'react-loader-spinner'
import { PageProps } from 'gatsby'
import clsx from 'clsx'

import { catalogAPI } from '@api/catalog'
import { IGetCallParams } from '@api/catalog/types'

import ModalGroup from '@components/modalGroup'
import Seo from '@components/seo'
import Card from '@components/discountCard'
import Pagination from '@components/pagination'
import Layout from '@components/layout'
import Select from '@components/select'
import LinkArrowLeft from '@svg/LinkArrowLeft'
import GeoSVG from '@svg/Geo'
import Container from '@ui/container'
import Typography from '@ui/typography'
import useWindowDimensions from '@hooks/useWindowDimensions'
import useKeepScrollPosition from '@hooks/useKeepScrollPosition'

import { discountSEO, headers, mainLink, promotionCount } from '@utils/constants'
import { getLastPathElem } from '@utils/utils'

import { IPromotion, IPromotionData } from 'types/catalog'
import { ICity } from 'types/agency'

import * as styles from '@styles/indexPage.module.scss'

interface IServerDataType {
  cities: ICity[]
  promotionsData: IPromotionData[]
}

const defaultCallModal = {
  isActive: false,
  idActive: '',
}

const Catalog: FC<PageProps<object, object, unknown, IServerDataType>> = ({ serverData }) => {
  const { cities = [], promotionsData = [] } = serverData || {}

  const { savePosition, returnPosition } = useKeepScrollPosition()

  const { isDesktop } = useWindowDimensions()

  const [callModal, setCallModal] =
    useState<{ isActive: boolean; idActive: string }>(defaultCallModal)
  const [isOkModalShow, setIsOkModalShow] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [city, setCity] = useState<ICity | undefined>(cities?.[0])
  const [isLoading, setIsLoading] = useState(false)
  const [promotionsList] = useState<IPromotionData[]>(promotionsData)

  const getCall = useCallback(
    async (data: IGetCallParams) => {
      return await catalogAPI.getCall(data)
    },
    [catalogAPI],
  )

  const showCallModalHandler = useCallback(
    (id: string) => setCallModal({ isActive: true, idActive: id }),
    [setCallModal],
  )

  const getActivePromotions = useMemo(() => {
    const data = promotionsList?.find(
      ({ cityId }) => city && cityId === getLastPathElem(city['@id']),
    )

    let promotionsByPage = data?.list?.promotions

    if (isDesktop) {
      promotionsByPage = []
      const startIndex = (currentPage - 1) * promotionCount
      const endIndex = promotionCount * currentPage - 1

      if (data?.list) {
        for (let i = startIndex; i <= endIndex; i++) {
          data?.list?.promotions?.[i] && promotionsByPage.push(data?.list.promotions[i])
        }
      }
    }

    return data?.list
      ? { count: data?.list?.count, promotions: promotionsByPage || [] }
      : { count: 0, promotions: [] }
  }, [promotionsList, city, currentPage, isDesktop])

  useEffect(() => void returnPosition(), [getActivePromotions])

  const increasePhoneViewCountHandler = (id: number, data: IPromotion) => {
    catalogAPI.increasePhoneViewCounter('promotions', id, data)
  }

  return (
    <Layout>
      <Seo
        title={discountSEO.title}
        description={discountSEO.description}
        keywords={discountSEO.keywords}
      />
      {isLoading && (
        <div className={styles.spinner}>
          <TailSpin color="#00AD64" height={30} width={30} />
        </div>
      )}
      <section className={styles.offer}>
        <Container>
          <a href={mainLink} className={styles.link}>
            <LinkArrowLeft />
            <Typography type="h4_bold" className={styles.toMain}>
              На главную
            </Typography>
          </a>
          <Typography type="h1_bold" component="h1" className={styles.title}>
            Акционные предложения от застройщиков в{' '}
            {city && (
              <Select
                className={styles.select}
                current={city}
                options={cities}
                setCurrent={(city: ICity) => setCity(city)}
                currentIcon={<GeoSVG className={styles.geo} />}
              />
            )}
          </Typography>
          <div
            className={clsx(styles.block, {
              [styles.block_mini]: getActivePromotions?.promotions?.length < 4,
            })}
          >
            {getActivePromotions?.promotions?.map((elem: IPromotion) => (
              <Card
                promotion={elem}
                key={elem['@id']}
                onSecondBtnClick={showCallModalHandler}
                onFirstBtnClick={() =>
                  increasePhoneViewCountHandler(+getLastPathElem(elem['@id']), elem)
                }
              />
            ))}
          </div>
          {isDesktop && getActivePromotions?.count > promotionCount && (
            <Pagination
              className={styles.pagination}
              count={Math.ceil(getActivePromotions.count / promotionCount)}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              beforeChangePage={savePosition}
            />
          )}
        </Container>
        <ModalGroup
          getCall={getCall}
          callModal={callModal}
          setCallModal={setCallModal}
          isOkModalShow={isOkModalShow}
          setIsOkModalShow={setIsOkModalShow}
          setIsLoading={setIsLoading}
          callModalContent={{
            subtitle: 'Оставьте Ваши данные и представитель застройщика перезвонит Вам.',
          }}
          okModalContent={{
            subtitle: 'В ближайшее время с Вами свяжется представитель застройщика.',
          }}
        />
      </section>
    </Layout>
  )
}

export async function getServerData() {
  const cities = (await catalogAPI.getCities(false, true)) || []

  let promotionsData = []

  if (cities?.length) {
    for (const city of cities) {
      const promotion = await catalogAPI.getPromotions(false, getLastPathElem(city['@id']))
      promotion?.promotions?.length &&
        promotionsData.push({ list: promotion, cityId: getLastPathElem(city['@id']) })
    }
  }

  return {
    props: {
      cities,
      promotionsData,
    },
    headers,
  }
}
export default memo(Catalog)
